import "./Box.scss"

const Box = ({inputBox, outputBox, sentences, isVisible}) => {
    return (
        <div id={`${inputBox ? "asr" : outputBox ? "translation" : ""}`} className={`box ${inputBox ? "box--input" : outputBox ? "box--output" : ""}`} style={{display : isVisible ? "block" : "none"}}>
            {sentences.length ? sentences.map((sentence, key) => <p key={key}>{sentence}</p>) : ''}
        </div>
    )
}

export default Box