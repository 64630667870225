import "./Home.scss"
import Transcription from "../transcription/Transcription";

const Home = ({showEstonian, showEnglish}) => {
    return (
        <section className={'home section__viewport'}>
            <Transcription showEstonian={showEstonian} showEnglish={showEnglish}/>
        </section>
    )
}

export default Home